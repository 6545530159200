/* eslint-disable no-unused-vars */
import { MembershipVisitUIStatus } from 'types/membership';
import { PaymentHistoryLine } from 'types/payment-history';

type PaymentHistoryIcons = 'money' | 'membership' | 'pill' | 'visits';
type VisitHistoryIcons = 'video' | 'chat' | 'video-chat';

export enum TransactionTypes {
    REFUND = 'Refund',
    BALANCE_SETTLEMENT = 'Balance settlement',
    CREDIT = 'Credit',
    MEMBERSHIP_FEE = 'Membership fee',
    PRESCRIPTION = 'Prescription',
    ONLINE_VISIT = 'Online visit'
}

export enum VisitTypes {
    VIDEO = 'Video',
    VIDEO_CHAT = 'Video Chat',
    CHAT = 'Chat'
}
export interface PaymentsType extends PaymentHistoryLine {
    transactionType: TransactionTypes;
    epostPatientNum: PaymentHistoryLine['epostPatientNum'];
}

export interface VisitType {
    transactionType: VisitTypes;
    date: string;
    name: string;
    epostPatientNum: string;
    status: MembershipVisitUIStatus
}

export interface TransactionCardBaseProps {
    subIconType?: 'default' | 'error' | 'refund' | 'positive' | 'check';
    hasDivider?: boolean;
    user?: string; // Provisional solution, as it depends on the API and the new fields to deal with caregiver/dependents
    userType?: 'caregiver' | 'dependent';
}

export enum TransactionCardVariant {
    PAYMENT = 'payment-history',
    HISTORY = 'visit-history'
}

export interface PaymentHistoryCardProps extends TransactionCardBaseProps {
    variant: TransactionCardVariant.PAYMENT;
    iconType: PaymentHistoryIcons;
    transactionInfo: PaymentsType;
}

export interface VisitHistoryCardProps extends TransactionCardBaseProps {
    variant: TransactionCardVariant.HISTORY;
    iconType: VisitHistoryIcons;
    hasDetails?: boolean;
    transactionInfo: VisitType;
}

export type TransactionCardProps = PaymentHistoryCardProps | VisitHistoryCardProps;
