import classNames from 'classnames';
import React, { CSSProperties } from 'react';

export interface CheckCircleIconProps {
    className?: string;
    style?: CSSProperties;
}
const CheckCircleIcon = ({ className, style }: CheckCircleIconProps) => {
    const classes = classNames('check-circle-icon', className);

    return (
        <div className={classes} style={style}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="12" fill="#D6F3E8" />
                <path
                    d="M17.0767 7.26923L10.3075 15.7308L6.92285 13.1923"
                    stroke="#00856E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default CheckCircleIcon;
