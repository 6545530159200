import { CSSProperties } from 'react';

export interface ChatIconProps {
    className?: string;
    style?: CSSProperties;
}

const ChatIcon = ({ className, style }: ChatIconProps) => {
    return (
        <div className={className} style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    d="M8.53846 9.23077H16.8462M8.53846 13.3846H14.0769M8.53846 19.6154L3 21L4.38462 16.8462V4.38462C4.38462 4.01739 4.5305 3.66521 4.79016 3.40554C5.04983 3.14588 5.402 3 5.76923 3H19.6154C19.9826 3 20.3348 3.14588 20.5944 3.40554C20.8541 3.66521 21 4.01739 21 4.38462V18.2308C21 18.598 20.8541 18.9502 20.5944 19.2098C20.3348 19.4694 19.9826 19.6154 19.6154 19.6154H8.53846Z"
                    stroke="#93A9B8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default ChatIcon;
