import { CSSProperties } from 'react';

export interface VideoChatIconProps {
    className?: string;
    style?: CSSProperties;
}

const VideoChatIcon = ({ className, style }: VideoChatIconProps) => {
    return (
        <div className={className} style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    d="M5.69231 13.4111L2 15.5L2.92308 12.7308V4.42308C2.92308 4.17826 3.02033 3.94347 3.19344 3.77036C3.36655 3.59725 3.60134 3.5 3.84615 3.5H13.0769C13.3217 3.5 13.5566 3.59725 13.7296 3.77036C13.9027 3.94347 14 4.17826 14 4.42308V8.03846M6.69231 7.5H10.5M22.2677 12.8889L19.2308 14.2778V12.6111C19.2308 12.3164 19.1173 12.0338 18.9153 11.8254C18.7134 11.6171 18.4395 11.5 18.1538 11.5H10.0769C9.7913 11.5 9.51739 11.6171 9.31542 11.8254C9.11346 12.0338 9 12.3164 9 12.6111V20.3889C9 20.6836 9.11346 20.9662 9.31542 21.1746C9.51739 21.3829 9.7913 21.5 10.0769 21.5H18.1538C18.4395 21.5 18.7134 21.3829 18.9153 21.1746C19.1173 20.9662 19.2308 20.6836 19.2308 20.3889V18.7222L22.2677 20.1111C22.3495 20.1437 22.4379 20.1552 22.5251 20.1444C22.6122 20.1338 22.6955 20.1013 22.7677 20.0498C22.8398 19.9983 22.8988 19.9294 22.9392 19.8491C22.9797 19.7688 23.0006 19.6794 23 19.5889V13.4111C23.0006 13.3206 22.9797 13.2312 22.9392 13.1509C22.8988 13.0706 22.8398 13.0017 22.7677 12.9502C22.6955 12.8987 22.6122 12.8662 22.5251 12.8555C22.4379 12.8448 22.3495 12.8563 22.2677 12.8889Z"
                    stroke="#93A9B8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default VideoChatIcon;
