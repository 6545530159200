import classNames from 'classnames';
import React from 'react';
import { Trans } from 'react-i18next';

import ChevronIcon from 'ui-kit/icons/chevron-thin-icon.tsx/chevron-thin-icon';

import IconComposer from 'display-components/icon-composer';

import { formatPrice } from 'schema/price.schema';

import { lowercaseAndCapitalize } from 'util/cart';
import { formatDisplayDate, getTransactionStatusDescription } from 'util/payment-history';
import { getCreditCardEnding } from 'util/payments';

import './transaction-card.styles.scss';
import { TransactionCardProps, TransactionCardVariant, TransactionTypes } from './transaction-card.types';

const TransactionCardComponent: React.FC<TransactionCardProps> = (props) => {
    switch (props.variant) {
        case TransactionCardVariant.HISTORY: {
            const { iconType, user, userType, hasDivider, hasDetails, subIconType, transactionInfo } = props;

            const containerClass = classNames('transaction-card', [
                { groupedView: hasDivider },
                TransactionCardVariant.HISTORY,
                { 'with-pointer': hasDetails }
            ]);

            const isIncompleteVisit = transactionInfo.status === 'INCOMPLETE';

            const statusClass = classNames('transaction-card-content-info-status', {
                isError: isIncompleteVisit
            });

            const onClick = () => {
                console.log('Ther card has been clicked');
            };

            return (
                <div className={containerClass} onClick={onClick}>
                    <IconComposer iconType={iconType} subIconType={subIconType} />
                    <div className="transaction-card-content">
                        <div className="transaction-card-content-title">
                            <p className={statusClass}>
                                {isIncompleteVisit ? (
                                    <Trans i18nKey="pages.visitHistory.errorMessages.incompleteVisit" />
                                ) : (
                                    transactionInfo.name
                                )}
                            </p>
                        </div>
                        <div className="transaction-card-content-info">
                            {user && userType === 'caregiver' && <span>{user}</span>}
                            <span className={statusClass}>{lowercaseAndCapitalize(transactionInfo.status)}</span>
                            {!isIncompleteVisit && (
                                <span>{formatDisplayDate(transactionInfo.date, true)}</span>
                            )}
                        </div>
                    </div>
                    {hasDetails && (
                        <div className="chevron-container">
                            <ChevronIcon direction="right" />
                        </div>
                    )}
                </div>
            );
        }
        case TransactionCardVariant.PAYMENT:
        default: {
            const { transactionInfo, iconType, subIconType, user, userType, hasDivider } = props;

            const containerClass = classNames('transaction-card', { groupedView: hasDivider });

            const {
                GLPostStatusNum,
                GLPostAmount,
                GLPostDatetime,
                orderPaymentCardTypeDesc,
                orderPaymentCardNumber,
                transactionType
            } = transactionInfo;

            const isPaymentRefund = transactionType === TransactionTypes.REFUND;
            const hasPaymentErrorStatus = getTransactionStatusDescription(Number(GLPostStatusNum)) === 'Payment Error';
            const statusClass = classNames('transaction-card-content-info-status', {
                isRefund: isPaymentRefund,
                isError: hasPaymentErrorStatus
            });
            const priceClass = classNames({ strikeThruPrice: hasPaymentErrorStatus });

            return (
                <div className={containerClass}>
                    <IconComposer
                        iconType={iconType}
                        subIconType={subIconType}
                        orderPaymentCardTypeDesc={orderPaymentCardTypeDesc}
                    />
                    <div className="transaction-card-content">
                        <div className="transaction-card-content-title">
                            <p>{transactionType}</p>
                            <span className={priceClass}>
                                {isPaymentRefund && '+'}
                                {formatPrice(GLPostAmount)}
                            </span>
                        </div>
                        <div className="transaction-card-content-info">
                            <span className={statusClass}>
                                {getTransactionStatusDescription(Number(GLPostStatusNum))}
                            </span>
                            <span>{formatDisplayDate(GLPostDatetime)}</span>
                            <span>
                                {orderPaymentCardTypeDesc} {getCreditCardEnding(orderPaymentCardNumber, '····')}
                            </span>
                            {user && (
                                <span>
                                    {user} {userType === 'caregiver' && '(you)'}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
    }
};

export default TransactionCardComponent;
