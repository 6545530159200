import { useTranslation } from 'gatsby-plugin-react-i18next';

import PaymentHistoryIcon from 'ui-kit/illustrations/payment-history/payment-history';

import EmptyState from 'display-components/empty-state/empty-state';

import TransactionCardComponent from 'components/transaction-card';
import { TransactionCardProps, TransactionCardVariant } from 'components/transaction-card/transaction-card.types';

import { groupTransactionsByMonth } from 'util/payment-history';

import './transaction-list.styles.scss';

export interface TransactionListProps {
    transactions: TransactionCardProps[];
    activeTab: string;
    onNavigate: (activeTab: string) => void;
    variant: TransactionCardVariant;
}

const TransactionList = ({ transactions, activeTab, onNavigate, variant }: TransactionListProps) => {
    const { t } = useTranslation();
    const filteredTransactions: TransactionCardProps[] = activeTab
        ? transactions.filter((transaction) => transaction.transactionInfo.epostPatientNum === activeTab)
        : transactions;

    const groupedTransactions = groupTransactionsByMonth(filteredTransactions, variant);

    return (
        <div className="transaction-list-container">
            {filteredTransactions.length > 0 ? (
                Object.keys(groupedTransactions).map((monthYear) => {
                    const hasManyTransactions = groupedTransactions[monthYear].length > 1;
                    return (
                        <div className="transaction-list-container-group" key={monthYear}>
                            <span className="transaction-list-container-group-title">{monthYear}</span>
                            <div
                                className={`transaction-list-container-group-items ${
                                    !hasManyTransactions ? 'solo-transaction' : ''
                                }`}
                            >
                                {groupedTransactions[monthYear].map((card, index) => (
                                    <TransactionCardComponent
                                        key={`${variant}-transaction-card-${index}`}
                                        {...card}
                                        hasDivider={hasManyTransactions}
                                    />
                                ))}
                            </div>
                        </div>
                    );
                })
            ) : (
                <EmptyState
                    icon={PaymentHistoryIcon}
                    title={t('pages.profile.paymentHistory.messages.title')}
                    description={t('pages.profile.paymentHistory.messages.description')}
                    buttonLabel={t('pages.profile.paymentHistory.ctas.goto')}
                    onNavigate={() => onNavigate('')}
                    buttonVariant="text-blue-underline"
                    dataGALocation="NoTransactionFound"
                />
            )}
        </div>
    );
};

export default TransactionList;
